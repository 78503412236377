import React from "react";
import {useTranslation} from "react-i18next";
import {logEvent} from "../../utils/amplitude";
import CloseIcon from '@material-ui/icons/Close';
import "./CardTemplate.css";
import StarIcon from '@material-ui/icons/Star';
import Dialog from '@material-ui/core/Dialog';

export const CardTemplate2 = (props) => {
    const {t} = useTranslation()
    const [playableUrl, setPlayableUrl] = React.useState("");
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const updateCurrentPlayable = (e) => {
        logEvent("showcase_play_add", {
            playable: props.playable.playable_id,
            example: props.playable.examples[getCurrentLang()][e].logo.split("-")[0],

        })
        if (isActiveCard()) {
            setPlayableUrl(evalPlayableUrl(e));
            setOpen(true);
            props.closeOpenPlayadsEvent();
        }
    }

    const addHoverClientLogo = (e) => {
        if (isActiveCard())
            e.target.classList.add('hover-client-logo')
        else
            e.target.classList.remove('hover-client-logo')
    }

    const getCardElement = () => document.getElementById(props.id)!.parentElement!.parentElement!;
    const isActiveCard = () => getCardElement().classList.contains("slick-center");
    const getCurrentLang = () => props.lang.toLowerCase().substring(0, 2);


    const evalPlayableUrl = (e) => {
        return eval('props.playable.examples[getCurrentLang()][e]["playable"]')
    }

    const {onClose, ...other } = props;
    return (
        <div id={props.id} key={props.id} className={"playable-wrapper"}>
            <div className={"image-wrapper"}>
                <div className={"card-image"} style={{
                    backgroundImage: `url(${props.playable.thumbnail})`}}>
                </div>
            </div>
                    <div className={"playable-description-container"}>
                        <div className={"playable-title"}>
                            <h3>{props.playable.displayName}</h3>
                        </div>
                        <div className={"playable-description"}>
                            <p>{props.playable.description[getCurrentLang()]}</p>
                            {
                                props.playable.studyCase[getCurrentLang()] !== '' &&
                                (<a className={"success-case"} target={"_blank"}
                                    href={props.playable.studyCase[getCurrentLang()]}>
                                    <StarIcon/>
                                    {t('read_case')}
                                </a>)
                            }
                        </div>

                        <p className={"examples"}>{props.playable.examples[getCurrentLang()].length>1 ? `${t('examples')}` : `${t('example')}`}</p>
                        <div className={"examples-wrapper"}>
                            {
                                props.playable.examples[getCurrentLang()]?.map((example: any, index: any) =>
                                    (

                                        <a key={index} id={index} onClick={() => updateCurrentPlayable(index)}>
                                            <img onMouseOver={addHoverClientLogo} onMouseOut={addHoverClientLogo}
                                                 src={`${example.logo}`}/>
                                        </a>
                                    ))
                            }
                        </div>
                        <p className={"metrics"}>{props.playable.metrics}</p>
                    </div>
            {
                playableUrl &&(
                        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className={playableUrl && props.playable.isLandscape ? "landscapeWrapper": ""}>
                            <CloseIcon className={"close-playable-btn"} onClick={handleClose}/>
                            <iframe src={playableUrl} className={"modal-iframe"} id={playableUrl && props.playable.isLandscape ? "landscape": ""}/>
                        </Dialog>
                )
            }
        </div>
    )
}
