import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import enFlag from "../assets/images/flag-en.png"
import esFlag from "../assets/images/flag-es.png"
import ptFlag from "../assets/images/flag-pt.png"
import {useTranslation} from "react-i18next";

export function LanguageSelect(props) {
    const currentLang = props.lang.toLowerCase().substring(0, 2);
    const handleChange = event => props.handleChangeLang(event.target.value);
    const {t} = useTranslation()

    return (
        <>
            <FormControl className={'form-control'} >
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currentLang}
                onChange={handleChange}
                defaultValue={currentLang}
                className={"select-language-desktop"}>
                <MenuItem value={'es'}>Español</MenuItem>
                <MenuItem value={'en'}>English</MenuItem>
                <MenuItem value={'pt'}>Português</MenuItem>
            </Select>
        </FormControl>

            <FormControl>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={currentLang}
                    defaultValue={currentLang}
                    onChange={handleChange}
                    className={"select-language-mobile"}>
                    <p style={{color: "#fff"}}>{t('language')}</p>
                    <div className={"align-flag"}>
                        <FormControlLabel value={'es'} control={<Radio />} label={""}/><img src={esFlag} alt={"spanish"}/>
                    </div>
                    <div className={"align-flag"}>
                        <FormControlLabel value={'en'} control={<Radio />} label={""}/><img src={enFlag} alt={"english"}/>
                    </div>
                    <div className={"align-flag"}>
                        <FormControlLabel value={'pt'} control={<Radio />} label={""}/><img src={ptFlag} alt={"portuguese"}/>
                    </div>
                </RadioGroup>
            </FormControl>
        </>

    );
}
