import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {CardTemplate2} from "./cardTemplate/CardTemplate2";
import Slider from "react-slick";
import {ArrowLeft, ArrowRight} from "@material-ui/icons";

export const Carousel = (props) => {

  const closeOpenPlayAds = () => {
        launchClickEvent(getSliderCards().filter((card) => card.classList.contains('flip-front-card')).shift()!);
    }

    const getSliderCards = () => Array.from(document.getElementsByClassName("flip-playable-inner"));

    const launchClickEvent = (element: Element) => {
        if (element) {
            document.getElementById("btn-" + element!.parentElement!.id)!.click()
        }
    }
    const buildCardTemplate = (playable: number, index: any) =>
        (<CardTemplate2 key={index} id={"playable-container-" + index} playable={playable} lang={props.lang}
                       className={"transition-scale-card"} closeOpenPlayadsEvent={closeOpenPlayAds}/>);

    const buildCardList = (playAds: any) => playAds.map(buildCardTemplate);

    const buildSettingObject = (slidesToShow, centerPadding, swipe = false) => {
        return {slidesToShow, centerPadding, swipe}
    }

    const settings = {
        className: 'center',
        infinite: true,
        speed: 500,
        centerMode: true,
        mobileFirst: true,
        focusOnSelect: true,
        centeredSlides: true,
        slidesPerView: 3,
        slidesToShow: 5,
        centerPadding: "20px",
        swipe: true,
        dots: true,
        appendDots: (dots: any[]) => {
            return (
                <>
                    {
                        dots.map((dot: any,index: number) => {
                            if (dot.props.className === "slick-active")
                                return (
                                    <div key={index} className={"arrow-slide-container"}>
                                        <ArrowLeft className={"left-arrow-container"} onClick={() => {
                                            let el = document.getElementsByClassName("slick-prev")[0] as HTMLElement;
                                            closeOpenPlayAds()
                                            el.click();}}/>
                                        <div style={{
                                            margin: '0',
                                            fontFamily: ''
                                        }}>{parseInt(dot.key) + 1}/{dots.length}</div>
                                        <ArrowRight className={"right-arrow-container"} onClick={() => {
                                            let el = document.getElementsByClassName("slick-next")[0] as HTMLElement;
                                            closeOpenPlayAds()
                                            el.click();
                                        }
                                        }/>

                                    </div>
                                )
                        })
                    }
                </>
            )
        },
        responsive: [
            {
                breakpoint: 821,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };
    return (
        <Slider {...settings}>
            {
                buildCardList(props.playAds)
            }
        </Slider>
    );
}
