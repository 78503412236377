import i18n from "i18next";
import { initReactI18next } from "react-i18next";
//import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './assets/locale/en.json';
import es from './assets/locale/es.json';
import pt from './assets/locale/pt.json';

const resources = {
    en: {
        translation: en
    },
    es: {
        translation: es
    },
    pt: {
        translation: pt
    },
};

i18n
    //.use(Backend)
    .use(LanguageDetector)
    //.use(initReactI18next)
    .init({
        resources,
        fallbackLng: "en",
        debug: true,
        keySeparator: false, // we use content as keys
        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ","
        },

        react: {
            wait: true
        }
    });

export default i18n;
