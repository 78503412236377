import {AppBar, IconButton, Drawer, Grid} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {LanguageSelect} from "../DropdownLanguages";
import SearchField from "react-search-field";
import "./Header.css";
import CloseIcon from '@material-ui/icons/Close';

export default function Header(props) {
    const [state, setState] = useState({
        mobileView: false,
        drawerOpen: false,
    });

    const {t} = useTranslation();
    const handleChange = (e) => {
        props.handleSearchFilter(e);
    }

    const {mobileView, drawerOpen} = state;

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 900
                ? setState((prevState) => ({...prevState, mobileView: true}))
                : setState((prevState) => ({...prevState, mobileView: false}));
        };

        setResponsiveness();

        window.addEventListener("resize", () => setResponsiveness());

        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
        };
    }, []);

    const displayDesktop = () => {
        return (
            <Grid container className={"header-wrapper"}>
                <Grid item md={6} xs={6} className={"title-container"}>
                    <h1 className={"title"}>{t('playable_ads')}</h1>
                </Grid>
                <Grid item md={6} className={"header-content"}>
                    <SearchField
                        placeholder={t('search')}
                        onChange={handleChange}
                        classNames="search"
                        onEnter={handleChange}
                        onSearchClick={handleChange}
                    />
                    <LanguageSelect handleChangeLang={props.handleChangeLang} lang={props.lang}/>
                </Grid>
            </Grid>
        );
    };

    const displayMobile = () => {
        const handleDrawerOpen = () =>
            setState((prevState) => ({...prevState, drawerOpen: true}));
        const handleDrawerClose = () =>
            setState((prevState) => ({...prevState, drawerOpen: false}));

        return (
            <Grid container className={"header-wrapper"}>
                <Drawer
                    {...{
                        anchor: "right",
                        open: drawerOpen,
                        onClose: handleDrawerClose,
                    }}
                >
                    <IconButton onClick={handleDrawerClose} className={"close-menu"}>
                        <CloseIcon className={"close-icon"}/>
                    </IconButton>
                    <div className={"drawer-container"}>
                        <LanguageSelect handleChangeLang={props.handleChangeLang} lang={props.lang}/>
                        <Grid item md={6} xs={12} className={"header-content"}>
                            <SearchField
                                placeholder={t('search')}
                                onChange={handleChange}
                                classNames="search"
                                onEnter={handleChange}
                                onSearchClick={handleChange}
                            />
                            <div className={"social-buttons-mobile"}>
                                <a href={"https://www.linkedin.com/company/11540468/"}><div className={"footer-social-icon linkedin"}></div></a>
                                <a href={"https://www.youtube.com/channel/UC1L94g9TqAca_TOIOO7asjw"}><div className={"footer-social-icon youtube"}></div></a>
                                <a href={"https://medium.com/etermax-brand-gamification/tagged/e"}><div className={"footer-social-icon medium"}></div></a>
                            </div>
                        </Grid>
                    </div>
                </Drawer>

                <Grid item md={6} xs={10}>
                    <h1 className={"title"}>{t('playable_ads')}</h1>
                </Grid>
                <Grid item xs={2} className={"menu-icon-container"}>
                    <IconButton className={"button-menu"}
                                {...{
                                    edge: "end",
                                    color: "inherit",
                                    "aria-label": "menu",
                                    "aria-haspopup": "true",
                                    onClick: handleDrawerOpen,
                                }}
                    >
                        <MenuIcon className={"menu-icon"}/>
                    </IconButton>
                </Grid>

            </Grid>
        );
    };


    return (
        <AppBar>
            {mobileView ? displayMobile() : displayDesktop()}
        </AppBar>
    );
}
