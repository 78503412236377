import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {Footer} from "./components/footer/Footer";
import {Carousel} from "./components/Carousel";
import Header from "./components/header/Header";


function Showcase() {

    const {t} = useTranslation()
    const [currentLang, setCurrentLang] = React.useState(i18n.language);
    const [searchFilter, setSearchFilter] = React.useState("");
    const [showClass, setShowClass] = React.useState("slider-hide");
    const [playAds, setPlayAds] = React.useState([]);
    const [playables, setPlayables] = useState([]);

    const handleChangeLang = useCallback((newLanguage) => {
        setCurrentLang(newLanguage)
        i18n.changeLanguage(newLanguage)
    }, [currentLang])

    const getFilteredPlayAds = (playAds: any) => isNotEmptySearchFilter() ? checkIfNeedDuplicateFilteredElements(playAds.filter(matchSearchPlayable)) : playAds;

    const checkIfNeedDuplicateFilteredElements = (filteredElements: []) => {
        return filteredElements.length.valueOf() == 4 ?
            filteredElements.concat(filteredElements) : filteredElements
    }

    const matchSearchPlayable = (playable: any) => getPlayableName(playable).includes(searchFilter.trim().toUpperCase());

    const isNotEmptySearchFilter = () => searchFilter.trim().length > 0;

    const getPlayableName = (playable: any) => t(`${playable.playable_id}_header`).toUpperCase();

    useEffect(() => {
        fetch(window.location.hostname.includes('staging') || window.location.hostname.includes('localhost') ? "https://showcase-playables.s3.amazonaws.com/playables-db-stg.json" : "https://showcase-playables.s3.amazonaws.com/playables-db.json")
            .then(response => response.json()).then(data => setPlayables(data.playables))
    }, [])


    useEffect(() => {

        setPlayAds(getFilteredPlayAds(playables));

        setShowClass("slider-hide");
        setTimeout(function () {
            setShowClass("slider-hide slider-show");
        }, 500);

    }, [searchFilter, playables])

    return (
        <div className="App">
            <Header lang={currentLang} handleChangeLang={handleChangeLang} handleSearchFilter={setSearchFilter}/>
            <main className="container">
                {
                    playAds.length > 0 &&
                    <Carousel lang={currentLang} searchFilter={searchFilter} playAds={playAds}/>

                }
            </main>
            <Footer handleChangeLang={handleChangeLang} lang={currentLang}/>
        </div>
    );
}

export default Showcase;
