import React, { useEffect} from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Showcase from "./Showcase";
import PlayableView from "./PlayableView";
import {initAmplitude, logEvent} from "./utils/amplitude";

function App() {

    useEffect(()=>{
        initAmplitude();
        logEvent("showcase_page_loaded")
    },[])


    return (

        <Router>
            <Route exact path='/' component={Showcase} />
            <Route path='/playable' component={PlayableView} />
        </Router>
    );
}

export default App;
