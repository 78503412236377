import React, {useEffect} from 'react';
import styled from "@emotion/styled";
import playAdsDesktop from "./config/Playable Cards - Desktop.json";


function PlayableView() {

    const [playableUrl, setPlayableUrl] = React.useState("");

    useEffect(() => {
        //alert(window.location.pathname);
        // localhost:3000/playables/block_challenge
        let playableName = window.location.pathname.replace("/playable/", "").replace("-", "_");
        setPlayableUrl(Array.from(playAdsDesktop.playables).find((playAd: any) => {
            return String(playAd.playable_id) == playableName;
        })!!.examples[0].en);
    }, );

    return (
        <div style={{width: '40rem', height: '40rem', margin: '0 auto', display: 'flex', marginTop: '5rem'}}>
            <IFrameContainer>
                <Iframe src={playableUrl}/>
            </IFrameContainer>
        </div>
    )
}

export default PlayableView;


const IFrameContainer = styled.div`
  width: 100%;
  height: 100%;
  @media (max-width: 768px) {
    height: 95%;
    margin-top: 5%;
  }
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
`;
