import React from 'react';
import logoEtermaxBG from "../../assets/images/logo-bg.svg"
import {LanguageSelect} from "../DropdownLanguages";
import useDeviceDetect from "../../hooks/useDeviceDetect";
import {useTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import "./Footer.css";

export const Footer = (props) => {
    const {t} = useTranslation()
    const {isMobile} = useDeviceDetect();

    return (
        <footer>
            <Grid container className={"wrapper-footer"}>
                    <Grid item xs={12} sm={5} lg={6}>
                        <img src={logoEtermaxBG} alt={"logo-brand-gamification"} className={"logo-BG-footer"}/>
                    </Grid>
                    <Grid item xs={8} sm={5} lg={6} className={"social-buttons-container"}>
                        <a href={"https://www.linkedin.com/company/11540468/"}><div className={"footer-social-icon linkedin"}></div></a>
                        <a href={"https://www.youtube.com/channel/UC1L94g9TqAca_TOIOO7asjw"}><div className={"footer-social-icon youtube"}></div></a>
                        <a href={"https://medium.com/etermax-brand-gamification/tagged/e"}><div className={"footer-social-icon medium"}></div></a>
                    </Grid>
            </Grid>

           <Grid container alignItems={"center"} className={"footer-terms-and-rights"}>
               <Grid item xs={12} sm={6} lg={6} className={"terms-and-conditions"}>
                   <a href={t('terms')}>{t('general_zocalo_t&c')}</a> <span>|</span> <a href={t('privacy')}>{t('general_zocalo_pp')}</a>
               </Grid>
               <Grid item xs={12} sm={6} lg={6} className={"rights-footer"}>
                   <p><span className={"span-footer"}>|</span> {t('general_zocalo_rights')}</p>
               </Grid>
           </Grid>
        </footer>
    )
}
